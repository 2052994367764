import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';
// import './index.css';
import './output.css';
import LandingPage from './pages/LandingPage/index.jsx';
import CustomerPage from './pages/CustomerPage/index.jsx';
import OwnerPage from './pages/OwnerPage/index.jsx';
import LoginPage from "./pages/LoginPage/index.jsx";
import LayoutPage from "./pages/LayoutPage/index.jsx";
import { createContext, Fragment, useState } from "react";
import { getToastConfig } from "./utils.js";

export const DataContext = createContext();

function App() {
    const [toastConfig, setToastConfig] = useState({});

    const showToastMessage = (content, variant, delayMs) => {
        const config = getToastConfig(content, variant);
        setToastConfig(config);
        setTimeout(() => {
            setToastConfig({});
        }, delayMs);
    };

    return (
        // <div className="App">
        //     <div className='gradient'></div>
        //     <div className='gradient2'></div>
        //     {/* <CustomerPage /> */}
        //     {/* <OwnerPage /> */}
        //     <LandingPage />
        // </div>
        <div className="App">
            <DataContext.Provider value={{ showToastMessage, toastConfig }}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<div data-theme="light">
                            <div className='gradient'></div>
                            <div className='gradient2'></div>
                            <LandingPage />
                        </div>}>
                        </Route>
                        {/* <Route path="customer" element={<CustomerPage />} /> */}
                        <Route path="app" element={<OwnerPage />} />
                        <Route path="login" element={<LoginPage />} />
                    </Routes>
                </BrowserRouter>
            </DataContext.Provider>
        </div>
    );
}

export default App;
