export default function Tabs({ tabs, onSelect, selectedTab }) {
    /*
    required tabs schema: 
        [{tabId: "tab_1", content: "Tab 1", defaultChecked: true}, {tabId: "tab_2", content: "Tab 2"}]
    */

    return <div role="tablist" className="tabs tabs-boxed gap-1">
        {
            (tabs || [])?.map(tab => {
                const { content, tabId } = tab || {};
                return <>
                    <a onClick={() => { onSelect(tabId); }} role="tab" className={`btn duration-100 tab ${(selectedTab === tabId) ? "tab-active" : ""}`}>{content}</a>
                    {/* <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
                        {content}
                    </div> */}
                </>;
            })
        }
    </div>;
}