import { useNavigate } from 'react-router-dom';
import person1 from '../assets/person1.jpeg';
import person2 from '../assets/person2.jpeg';
import person3 from '../assets/person3.jpeg';
import person4 from '../assets/person4.jpeg';
import person5 from '../assets/person5.jpeg';
import sendIcon from '../assets/SendIcon.svg';

export default function Hero() {
    const navigate = useNavigate();
    return <div className="hero min-h-screen">
        <div className="hero-content text-center">
            <div className="max-w-2xl flex flex-col gap-4 items-center">
                <h1 className="font-extrabold text-4xl lg:text-5xl tracking-tight md:-mb-4">Focus on customer needs,</h1>
                <h1 className="font-extrabold text-4xl lg:text-5xl tracking-tight md:-mb-4"> Not your assumptions</h1>
                <br />
                <p className="text-lg text-base-content-secondary leading-relaxed max-w-md mx-auto">Let Users Voice Their Feedback and Feature Ideas, So You Can Build What They Truly Want.</p>
                <br />
                <button className="btn btn-block group w-64" onClick={() => { navigate("/login"); }} style={{ background: 'darksalmon', color: 'white' }}>Get AdviceFast <img src={sendIcon} style={{ width: '25px' }} /></button>
                <br />
                <div className='flex gap-4'>
                    <div className="avatar-group -space-x-6 rtl:space-x-reverse">
                        <div className="avatar">
                            <div className="w-12">
                                <img src={person1} />
                            </div>
                        </div>
                        <div className="avatar">
                            <div className="w-12">
                                <img src={person2} />
                            </div>
                        </div>
                        <div className="avatar">
                            <div className="w-12">
                                <img src={person3} />
                            </div>
                        </div>
                        <div className="avatar">
                            <div className="w-12">
                                <img src={person4} />
                            </div>
                        </div>
                        <div className="avatar">
                            <div className="w-12">
                                <img src={person5} />
                            </div>
                        </div>
                        <div className="avatar placeholder">
                            <div className="bg-neutral text-neutral-content w-12">
                                <span>+99</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col items-start justify-center gap-2'>
                        <div className="rating rating-sm">
                            <input type="radio" name="rating-6" style={{ pointerEvents: 'none', background: 'rgb(251 146 60 / var(--tw-bg-opacity))' }} className="mask mask-star-2 bg-orange-400" />
                            <input type="radio" name="rating-6" style={{ pointerEvents: 'none', background: 'rgb(251 146 60 / var(--tw-bg-opacity))' }} className="mask mask-star-2 bg-orange-400" />
                            <input type="radio" name="rating-6" style={{ pointerEvents: 'none', background: 'rgb(251 146 60 / var(--tw-bg-opacity))' }} className="mask mask-star-2 bg-orange-400" />
                            <input type="radio" name="rating-6" style={{ pointerEvents: 'none', background: 'rgb(251 146 60 / var(--tw-bg-opacity))' }} className="mask mask-star-2 bg-orange-400" />
                            <input type="radio" name="rating-6" style={{ pointerEvents: 'none', background: 'rgb(251 146 60 / var(--tw-bg-opacity))' }} className="mask mask-star-2 bg-orange-400" defaultChecked />
                        </div>
                        <div>
                            <h3 className="text-sm text-base-content/80">452 founders hear better</h3>
                            {/* <h3 className="text-sm text-base-content/80">are hearing customers</h3> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}