import React, { useContext, useEffect, useState } from "react";
import LayoutPage from "../LayoutPage";
import { DataContext } from "../../App";
import Tabs from "../../components/Tabs";
import { getSession, handleResendCode, handleSign, setSession } from "../../utils";
import Counter from "../../components/Counter";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
    const navigate = useNavigate();
    const { showToastMessage } = useContext(DataContext);
    const [enableResendCode, setEnableResendCode] = useState(true);
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [selectedSignVariant, setSelectedSignVariant] = useState("login");
    const tabs = [{
        tabId: "login",
        content: "Login"
    }, {
        tabId: "signup",
        content: "Signup"
    }];

    // useEffect(() => {
    //     if (getSession()?.merchant?.token) {
    //         navigate("/app");
    //     }
    // }, []);

    return <div style={{
        width: "100%",
    }}>
        <LayoutPage />
        {/* Background Graphics */}
        <div className='gradient'></div>
        <div className='gradient2'></div>

        {(selectedSignVariant === "login" || selectedSignVariant === "signup") && <div className="space-y-4 bg-base-100 rounded-box p-8 hover:shadow-lg duration-200 custom-card w-4/12 h-fit z-50" style={{ padding: '20px', zIndex: 99999, position: 'relative', width: '430px', margin: 'auto', marginTop: '120px' }}>
            <div className="card-body" style={{ padding: '10px', gap: '20px' }}>
                {/* <h2 className="font-bold text-lg text-center">{"Login"}</h2> */}
                <div>
                    <Tabs tabs={tabs} selectedTab={selectedSignVariant} onSelect={(selectedTabId) => { setSelectedSignVariant(selectedTabId); }} />
                </div>
                <form onSubmit={async (e) => {
                    e.preventDefault();
                    setIsBtnLoading(true);
                    const email = document.querySelector("#email")?.value;
                    const password = document.querySelector("#password")?.value;
                    setSession({
                        merchant: {
                            email,
                            password
                        }
                    });
                    const data = await handleSign(selectedSignVariant, email, password, showToastMessage);
                    if (data?.error) {
                        showToastMessage(data?.message, "error", 5000);
                        if (data?.errorCode === "UsernameExistsException") {
                            setSelectedSignVariant("login");
                        }
                        else if (data?.errorCode === "UserNotConfirmedException") {
                            const response = await handleResendCode(getSession()?.merchant?.email);
                            setEnableResendCode(false);
                            setSelectedSignVariant("verifyEmail");
                        }
                        // return;
                    }
                    else {
                        if (data?.userConfirmed === false) {
                            setSelectedSignVariant("verifyEmail");
                            setSession({
                                merchant: data
                            });
                            showToastMessage(`Please Verify Email`, "info", 4000);
                        }
                        else if (data?.token) {
                            setSession({
                                merchant: {
                                    ...getSession()?.merchant,
                                    ...data
                                }
                            });
                            showToastMessage(`Successfully Loggedin`, "success", 4000);
                            navigate("/app");
                        }
                    }
                    setIsBtnLoading(false);
                }}>
                    <div className="flex flex-col gap-5">
                        {/* Email Field */}
                        <div className="flex flex-col gap-4">
                            <label htmlFor="email" className="label-text">Email</label>
                            <input
                                type="email"
                                id="email"
                                placeholder="Your email"
                                className="input border-base-content/10 placeholder:opacity-60 font-medium"
                                required
                            />
                        </div>

                        {/* Password Field */}
                        <div className="flex flex-col gap-4 mb-6">
                            <label htmlFor="password" className="label-text">Password</label>
                            <input
                                type="password"
                                id="password"
                                placeholder="Your password"
                                className="input border-base-content/10 placeholder:opacity-60 font-medium"
                                required
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}"
                                title="Must contain at least one number, one uppercase and lowercase letter, one special character, and at least 8 or more characters"
                            />
                        </div>
                    </div>

                    {/* Submit Button */}
                    <button type="submit" className="btn w-full tab-active">{isBtnLoading ? <span class="loading loading-spinner"></span> : "Submit"}</button>
                </form>
                <div>
                </div>
            </div>
        </div>}

        {(selectedSignVariant === "verifyEmail") && <div className="space-y-4 bg-base-100 rounded-box p-8 hover:shadow-lg duration-200 custom-card w-4/12 h-fit z-50" style={{ padding: '20px', zIndex: 99999, position: 'relative', width: '430px', margin: 'auto', marginTop: '120px' }}>
            <div className="card-body" style={{ padding: '10px', gap: '20px' }}>
                {/* <h2 className="font-bold text-lg text-center">{"Verify Emailaddress"}</h2> */}
                <form onSubmit={async (e) => {
                    e.preventDefault();
                    setIsBtnLoading(true);
                    const email = getSession()?.merchant?.email;
                    const confirmationCode = document.querySelector("#confirmation-code")?.value;
                    const data = await handleSign(selectedSignVariant, email, confirmationCode, showToastMessage);
                    if (data?.error) {
                        showToastMessage(data?.message, "error", 5000);
                        if (data?.errorCode === "UsernameExistsException") {
                            setSelectedSignVariant("login");
                        }
                        else if (data?.errorCode === "UserNotConfirmedException") {
                            setSelectedSignVariant("verifyEmail");
                        }
                    }
                    else {
                        setSession({
                            merchant: {
                                ...getSession().merchant,
                                ...data
                            }
                        });
                        showToastMessage(`Successfully Confirmed`, "success", 4000);
                    }
                    setIsBtnLoading(false);
                }}>
                    <div className="flex flex-col gap-5 mb-6">
                        {/* Email Field */}
                        <div className="flex flex-col gap-4">
                            <label htmlFor="email" className="label-text">Email</label>
                            <input
                                type="email"
                                id="email"
                                disabled
                                placeholder={getSession()?.merchant?.email}
                                className="input border-base-content/10 placeholder:opacity-60 font-medium"
                                required
                            />
                        </div>
                        <div className="flex flex-col gap-4">
                            <label htmlFor="confirmationCode" className="label-text">Confirmation Code</label>
                            <input
                                type="text"
                                id="confirmation-code"
                                placeholder={"Eg: 472363"}
                                className="input border-base-content/10 placeholder:opacity-60 font-medium"
                                required
                            />
                        </div>
                    </div>

                    {/* Submit Button */}
                    <button type="submit" className="btn w-full tab-active">{isBtnLoading ? <span class="loading loading-spinner"></span> : "Submit"}</button>
                </form>
                <div className="flex flex-col gap-4 justify-center items-center">
                    <button disabled={!enableResendCode} onClick={async () => {
                        const response = await handleResendCode(getSession()?.merchant?.email);
                        setEnableResendCode(false);
                    }} className="btn w-full">Resend Code</button>
                    {!enableResendCode && <Counter initialVal={{ min: 1, sec: 30 }} onEnd={() => {
                        setEnableResendCode(true);
                    }} />}
                </div>
            </div>
        </div>
        }
    </div >
}