import React, { useEffect, useState } from "react";

export default function Counter({ initialVal = { min: 0, sec: 0 }, onEnd }) {
    const [min, setMin] = useState(initialVal.min);
    const [sec, setSec] = useState(initialVal.sec);

    useEffect(() => {
        const timer = setInterval(() => {
            if (min === 0 && sec === 0) {
                clearInterval(timer);
                if (onEnd) onEnd(); // Trigger the onEnd callback if defined
            } else if (sec > 0) {
                setSec((prevSec) => prevSec - 1);
            } else if (sec === 0 && min > 0) {
                setMin((prevMin) => prevMin - 1);
                setSec(59); // Reset seconds to 59 for the next minute
            }
        }, 1000);

        return () => clearInterval(timer); // Clear timer on component unmount
    }, [min, sec, onEnd]);

    return (
        <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
            <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                <span className="countdown font-mono text-2xl">
                    <span style={{ "--value": min }}></span>
                </span>
                min
            </div>
            <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                <span className="countdown font-mono text-2xl">
                    <span style={{ "--value": sec }}></span>
                </span>
                sec
            </div>
        </div>
    );
}