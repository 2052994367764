import { useContext } from "react";
import { DataContext } from "../../App";

export default function LayoutPage() {
    const { toastConfig } = useContext(DataContext);

    return toastConfig?.content && <div className="toast toast-center">
        <div className={toastConfig?.class}>
            <span style={{ color: 'white' }}>{toastConfig?.content}</span>
        </div>
    </div>;
}