import React, { useContext, useEffect, useState } from "react";
import Tabs from "../../components/Tabs";
import './index.css';
import Comment from "../../components/Comment";
import LayoutPage from "../LayoutPage";
import NavBar from "../../components/NavBar";
import { useNavigate } from "react-router-dom";
import logoIcon from '../../assets/logo-3.png';
import { getSession, handleAddOrigins, retrieveComments, setSession } from "../../utils";
import { DataContext } from "../../App";

export default function OwnerPage() {
    const { showToastMessage } = useContext(DataContext);
    const [selectedInputVariant, setSelectedInputVariant] = useState("feature");
    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [totalComments, setTotalComments] = useState(0);
    const pageLength = 5;
    const [collapseOrigins, setCollapseOrigins] = useState(true);
    const [origins, setOrigins] = useState([]);
    const [enteredOrigin, setEnteredOrigin] = useState("");
    const [sentimentAnalysis, setSentimentAnalysis] = useState({});
    const inputBoxConfig = {
        feature: {
            heading: {
                label: "Suggest a feature"
            },
            titleInputBox: {
                label: "Short, Descriptive title",
                placeholder: "Custom Popups"
            },
            descriptionInputBox: {
                label: "Description",
                placeholder: "I want to add custom popups with custom trigger rules to gather extra data"
            }
        },
        feedback: {
            heading: {
                label: "Provide Feedback"
            },
            titleInputBox: {
                label: "Short, Descriptive title",
                placeholder: "Ease of Use"
            },
            descriptionInputBox: {
                label: "Your Feedback",
                placeholder: "The product could be improved by adding a tutorial for new users."
            }
        }
    }

    const handleCopy = (val) => {
        navigator.clipboard.writeText(val);
        showToastMessage(<div className="flex gap-2">
            <div style={{ width: '20px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path fill="white" d="M384 336l-192 0c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l140.1 0L400 115.9 400 320c0 8.8-7.2 16-16 16zM192 384l192 0c35.3 0 64-28.7 64-64l0-204.1c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1L192 0c-35.3 0-64 28.7-64 64l0 256c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l192 0c35.3 0 64-28.7 64-64l0-32-48 0 0 32c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l32 0 0-48-32 0z" />
                </svg>
            </div>
            <h3>Copied to Clipboard</h3>
        </div>, "neutral", 3000);
    };

    const tabs = [{
        tabId: "feature",
        content: "Ask Feature"
    }, {
        tabId: "feedback",
        content: "Give Feedback"
    }];

    const [comments, setComments] = useState([]);

    const [selectedPage, setSelectedPage] = useState("landing-page");
    const navigate = useNavigate();
    const pages = [{
        id: "pricing",
        label: "Pricing"
    }]
    const onPageClick = (selectedPageId) => {
        setSelectedPage(selectedPageId);
    };

    const retrieveSentimentAnalysis = async (token) => {
        try {
            const response = await fetch("http://localhost:8081/admin/analysis", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-admin-access-token": `${token}`
                }
            });
            if (response?.ok) {
                return await response?.json();
            }
            else {
                console.log("Error retrieving sentiment analysis");
                showToastMessage("Failed to retrieve analysis", "error", 4000);
            }
        } catch (error) {
            console.log("Error retrieving sentiment analysis", error);
            showToastMessage("Failed to retrieve analysis", "error", 4000);
        }
    };

    const getAndSetComments = async () => {
        try {
            const url = `http://localhost:8081/admin/comments`
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "x-admin-access-token": getSession()?.merchant?.token
                },
                body: JSON.stringify({
                    limit: pageLength,
                    offset: (currentPageNum - 1) * pageLength // Calculate offset for pagination
                })
            });
            if (response?.ok) {
                response = await response?.json();
                setComments(response?.data);
                setTotalComments(response?.total);
            }
            else {
                console.log("Error retrieving comments");
                showToastMessage("Failed to retrieve comments", "error", 4000);
            }
        } catch (error) {
            console.log("Error retrieving comments", error);
            showToastMessage("Failed to retrieve comments", "error", 4000);
        }
    };

    const onLoad = async () => {
        // retrieve origins
        const session = getSession();
        setOrigins(session?.merchant?.origins);

        // retrieve comments
        await getAndSetComments();

        // retrieve sentiment analysis
        const sentimentAnalysis = await retrieveSentimentAnalysis(getSession()?.merchant?.token);
        setSentimentAnalysis(sentimentAnalysis?.data);
    };

    useEffect(() => {
        onLoad();
    }, []);

    function validateHost(input) {
        const pattern = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/;
        const isValid = pattern.test(input.value);

        if (isValid) {
            input.setCustomValidity('');  // Clear any previous error
        } else {
            input.setCustomValidity('Please enter a valid hostname, such as example.com');
        }
    }

    function formatSentimentScore(val) {
        if (val === null || val === undefined) {
            return {
                score: 100,
                color: "darksalmon"
            }
        }

        val = (val || 0)?.toFixed(2);
        let color;

        // Determine color based on the score
        const scorePercentage = (val / 5) * 100;
        if (scorePercentage < 35) {
            color = "oklch(var(--er))";
        } else if (scorePercentage >= 35 && scorePercentage <= 70) {
            color = "oklch(var(--wa))";
        } else {
            color = "oklch(var(--su))";
        }

        return {
            score: scorePercentage,
            color: color
        }
    }

    useEffect(() => {
        if (getSession()?.merchant?.token) {
            getAndSetComments();
        }
    }, [currentPageNum]);

    return <div>
        <div className="flex justify-end p-6">
            <div className="dropdown dropdown-end">
                <div tabIndex={0} role="button" className="btn btn-ghost rounded-btn">My Account</div>
                <ul tabIndex={0} className="menu dropdown-content bg-base-100 rounded-box z-[1] mt-4 w-fit p-2 shadow">
                    <li className="p-4 text-slate-500">{getSession()?.merchant?.email}</li>
                    <li onClick={() => {
                        setSession({});
                        navigate("/");
                    }}><a>Logout</a></li>
                </ul>
            </div>
        </div>
        <div className="w-full text-start px-20 flex gap-10">
            <LayoutPage />
            {/* Input for Customer to take in the Feedback / Suggestion */}
            {selectedInputVariant ? <div className="flex flex-col gap-5 w-4/12">
                <div className="space-y-4 bg-base-100 rounded-box p-8 hover:shadow-lg duration-200 custom-card h-fit" style={{ padding: '20px' }}>
                    <div className="card-body" style={{ padding: '10px', gap: '20px' }}>
                        <h2 className="font-bold text-lg">Analysis</h2>
                        <div className="flex gap-5">
                            <div className="flex gap-2">
                                <h3 className="label-text">Features:</h3>
                                <h3 className="font-bold label-text">{sentimentAnalysis?.noOfFeatures || 0}</h3>
                            </div>
                            <div className="flex gap-2">
                                <h3 className="label-text">Feedbacks:</h3>
                                <h3 className="font-bold label-text">{sentimentAnalysis?.noOfFeedbacks || 0}</h3>
                            </div>
                        </div>
                        <div className="flex flex-col gap-5">
                            <h3 className="font-bold label-text">Sentiment Analysis:</h3>
                            <div className="flex gap-8">
                                <div className="flex flex-col gap-3 text-center w-fit">
                                    <div className="radial-progress" style={{ marginLeft: '15px', "--value": formatSentimentScore(sentimentAnalysis?.avgSaScoreOfFeatures)?.score, color: formatSentimentScore(sentimentAnalysis?.avgSaScoreOfFeatures)?.color }} role="progressbar">
                                        {formatSentimentScore(sentimentAnalysis?.avgSaScoreOfFeatures)?.score ? `${formatSentimentScore(sentimentAnalysis?.avgSaScoreOfFeatures)?.score}%` : 'NA'}
                                    </div>
                                    <h3 className="label-text">Features</h3>
                                </div>
                                <div className="flex flex-col gap-3 text-center w-fit">
                                    <div className="radial-progress" style={{ marginLeft: '15px', "--value": formatSentimentScore(sentimentAnalysis?.avgSaScoreOfFeedbacks)?.score, color: formatSentimentScore(sentimentAnalysis?.avgSaScoreOfFeedbacks)?.color }} role="progressbar">
                                        {sentimentAnalysis?.avgSaScoreOfFeedbacks?.score ? `${formatSentimentScore(sentimentAnalysis?.avgSaScoreOfFeedbacks)?.score}%` : 'NA'}
                                    </div>
                                    <h3 className="label-text">Feedbacks</h3>
                                </div>
                                <div className="flex flex-col gap-3 text-center w-fit">
                                    <div className="radial-progress" style={{ marginLeft: '15px', "--value": formatSentimentScore(sentimentAnalysis?.avgSaScoreOfOverallComments)?.score, color: formatSentimentScore(sentimentAnalysis?.avgSaScoreOfOverallComments)?.color }} role="progressbar">
                                        {formatSentimentScore(sentimentAnalysis?.avgSaScoreOfOverallComments)?.score ? `${formatSentimentScore(sentimentAnalysis?.avgSaScoreOfOverallComments)?.score}%` : 'NA'}
                                    </div>
                                    <h3 className="label-text">Overall</h3>
                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>

                <div className="space-y-4 bg-base-100 rounded-box p-8 hover:shadow-lg duration-200 custom-card h-fit" style={{ padding: '20px' }}>
                    <div className="card-body" style={{ padding: '10px', gap: '20px' }}>
                        <h2 className="font-bold text-lg">Your Script Link</h2>
                        <div className="flex gap-2">
                            <input
                                style={{ width: '100%' }}
                                type="text"
                                id="script-link"
                                value={getSession()?.merchant?.scriptTag}
                                disabled
                                className="input border-base-content/10 placeholder:opacity-60 font-medium"
                                required
                            />
                            <button className="btn tab-active" style={{ width: '50px' }} onClick={() => {
                                handleCopy(getSession()?.merchant?.scriptTag);
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="white" d="M384 336l-192 0c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l140.1 0L400 115.9 400 320c0 8.8-7.2 16-16 16zM192 384l192 0c35.3 0 64-28.7 64-64l0-204.1c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1L192 0c-35.3 0-64 28.7-64 64l0 256c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l192 0c35.3 0 64-28.7 64-64l0-32-48 0 0 32c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l32 0 0-48-32 0z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="card-body" style={{ padding: '10px', gap: '20px' }}>
                        <h2 className="font-bold text-lg">Your Client Id</h2>
                        <div className="flex gap-2">
                            <input
                                style={{ width: '100%' }}
                                type="text"
                                id="email"
                                value={getSession()?.merchant?.merchant_id}
                                disabled
                                className="input border-base-content/10 placeholder:opacity-60 font-medium"
                                required
                            />
                            <button onClick={() => {
                                handleCopy(getSession()?.merchant?.merchant_id);
                            }} className="btn tab-active" style={{ width: '50px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="white" d="M384 336l-192 0c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l140.1 0L400 115.9 400 320c0 8.8-7.2 16-16 16zM192 384l192 0c35.3 0 64-28.7 64-64l0-204.1c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1L192 0c-35.3 0-64 28.7-64 64l0 256c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l192 0c35.3 0 64-28.7 64-64l0-32-48 0 0 32c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l32 0 0-48-32 0z" />
                                </svg>
                            </button>
                        </div>
                        {/* <button type="submit" className="btn w-full tab-active">{"Copy"}</button> */}
                    </div>
                </div>
            </div>
                : <div className="card bg-base-100 w-96 shadow-xl" style={{ padding: '20px', width: '60%' }}>
                    <div className="card-body">
                        <div class="skeleton h-4 w-20"></div>
                    </div>
                    <textarea
                        placeholder="Bio"
                        class="textarea textarea-bordered textarea-sm w-full"></textarea>
                </div>}

            {/* Show the top Suggestions */}
            <div className="flex flex-col w-7/12 gap-7">
                {/* <div className="space-y-6 w-full">
                    <div className="custom-card flex bg-base-100 rounded-box p-6 duration-200 justify-between items-center gap-4" style={{ background: 'darksalmon', color: 'white' }}>
                        <div>
                            <div class="font-semibold mb-0.5 flex gap-2 items-start">{"Summary"}</div>
                            <div class="text-base-secondary leading-relaxed mb-2" style={{ color: 'white' }}>{"Being able to exclude my visits from my site is super important, similar to how Plausible does it: https://plausible.io/docs/excluding-localstorage"}</div>
                            <div class="flex gap-1.5 items-center text-base-secondary" style={{ color: "white", height: '10px' }}>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="space-y-6 w-full">
                    <div className="custom-card flex bg-base-100 rounded-box p-6 duration-200 justify-between items-center gap-4" style={{ paddingBottom: '0px' }}>
                        {/* style={{ background: 'darksalmon', color: 'white' }} */}
                        <div className="flex flex-col gap-3 w-full">
                            <div class="font-semibold mb-0.5 flex gap-2 items-start">{"Your Hosts (Whitelisted)"}</div>
                            {/* <div class="text-base-secondary leading-relaxed mb-2" style={{ color: 'white' }}>{"Being able to exclude my visits from my site is super important, similar to how Plausible does it: https://plausible.io/docs/excluding-localstorage"}</div>
                            <div class="flex gap-1.5 items-center text-base-secondary" style={{ color: "white", height: '10px' }}>
                            </div> */}
                            {/* <div className="flex gap-3">
                                {origins?.map(origin => {
                                    return <div className="badge badge-outline">{origin}</div>;
                                })}
                            </div> */}
                            <form onSubmit={async (e) => {
                                e.preventDefault();
                                // call the api to add it in the merchant mongo
                                const session = getSession();
                                const updatedOrigins = await handleAddOrigins(session?.merchant?.token, session?.merchant?.email, [...origins, enteredOrigin]);
                                if (updatedOrigins?.error) {
                                    showToastMessage(updatedOrigins?.message, "error", 4000);
                                    return;
                                }
                                setOrigins(updatedOrigins?.origins);
                                // call the api again to retrive merchant data
                            }}>
                                <div className="flex gap-3">
                                    {/* <div className="mockup-browser bg-base-300 border"> */}
                                    {/* <div className="mockup-browser-toolbar"> */}
                                    <input
                                        style={{ color: "black" }}
                                        type="text"
                                        id="new-host"
                                        placeholder="example.com"
                                        class="input input-bordered w-full"
                                        pattern="^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$"
                                        title="Please enter a valid hostname (e.g., example.com)"
                                        required
                                        onChange={(e) => {
                                            setEnteredOrigin(e?.target?.value);
                                            e.preventDefault();
                                        }}
                                    />
                                    {/* </div> */}
                                    {/* </div> */}
                                    <button type={'submit'} disabled={((origins || [])?.find((item) => (item === enteredOrigin)))} className="btn tab-active" style={{ width: '70px' }}>Add</button>
                                </div>
                            </form>

                            <div class="collapse collapse-arrow" style={{ padding: "0px" }} onClick={() => {
                                setCollapseOrigins(!collapseOrigins);
                            }}>
                                <input type="checkbox" checked={collapseOrigins} />
                                <div class="collapse-title font-semibold mb-0.5 flex gap-2 items-start" style={{ paddingLeft: '0px' }}>Added Hosts</div>
                                <div class="collapse-content" style={{ padding: '0px' }}>
                                    <div className="space-y-6 w-full">
                                        <div className="mockup-code" style={{ marginBottom: '25px' }}>
                                            <div className="flex flex-col gap-3" style={{ maxHeight: '150px', overflow: 'scroll' }}>
                                                {origins?.length > 0 ? origins?.map((origin, id) => {
                                                    return <pre data-prefix="$"><code>{origin}</code></pre>;
                                                }) : <pre data-prefix="$"><code>{"No Hosts Found"}</code></pre>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-7">
                    {(comments || [])?.map(comment => {
                        return <Comment isAdmin={true} onSelect={(selectedCommentId) => {
                            console.log(selectedCommentId);
                        }} commentData={comment} />;
                    })}
                </div>
                {/* Pagination */}
                <div className="join mb-10 gap-3 self-center">
                    <button className="join-item btn" onClick={() => { setCurrentPageNum(Math.max(1, currentPageNum - 1)); }} disabled={currentPageNum === 1}>«</button>
                    <button className="join-item btn">{`Page ${currentPageNum}`}</button>
                    <button className="join-item btn" onClick={() => { setCurrentPageNum(currentPageNum + 1); }} disabled={currentPageNum * pageLength >= totalComments}>»</button>
                </div>
            </div>
        </div >
    </div>
}