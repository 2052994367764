import { sessionStorageMerchantKey } from "./constants";


const API_ENDPOINT = "http://localhost:8081";

export const getToastConfig = (content, variant) => {
    let config = {};
    if (variant === "error") {
        config = {
            class: "alert alert-error"
        }
    }
    else if (variant === "success") {
        config = {
            class: "alert alert-success"
        }
    }
    else if (variant === "neutral") {
        config = {
            class: "alert bg-neutral"
        }
    }
    else {
        config = {
            class: "alert alert-info"
        }
    }
    return {
        ...config,
        content
    };
};

export const getSession = () => {
    return JSON.parse(sessionStorage.getItem(sessionStorageMerchantKey) || '{}');
}

export const setSession = (payload) => {
    return sessionStorage.setItem(sessionStorageMerchantKey, JSON.stringify(payload));
}

export const handleResendCode = async (email) => {
    try {
        const url = `${API_ENDPOINT}/resend-code`
        let response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                email
            })
        });
        response = await response?.json();
        return response;
    } catch (error) {
        return {
            error: true,
            message: error?.message
        }
    }
};

export const handleSign = async (signVariant, email, password, showToastMessage) => {
    let response = "";
    if (signVariant === 'login') {
        try {
            const url = `${API_ENDPOINT}/signin`
            response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    password
                })
            });
            response = await response?.json();
        } catch (error) {
            console.log("MANI: ", error);
            return {
                error: true,
                message: error?.message
            }
        }
    }
    else if (signVariant === 'signup') {
        try {
            const url = `${API_ENDPOINT}/signup`
            response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    password
                })
            });
            response = await response?.json();
        } catch (error) {
            return {
                error: true,
                message: error?.message
            }
        }
    }
    else if (signVariant === 'verifyEmail') {
        try {
            const url = `${API_ENDPOINT}/confirm`
            response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    confirmationCode: parseInt(password)
                })
            });
            response = await response?.json();
        } catch (error) {
            return {
                error: true,
                message: error?.message
            }
        }
    }
    console.log("MANI: ", response?.errorCode);
    if (response?.errorCode === "UserNotConfirmedException") {
        return {
            error: true,
            message: "Please verify your email address",
            errorCode: response?.errorCode
        }
    }
    else if (response?.errorCode === "UsernameExistsException") {
        return {
            error: true,
            message: "You are already registered, Please Login",
            errorCode: response?.errorCode
        }
    }
    else if (response?.errorCode === "NotAuthorizedException") {
        return {
            error: true,
            message: "You are not registered, Please Signup",
            errorCode: response?.errorCode
        }
    }
    return response;
};

export const handleAddOrigins = async (token, email, origins) => {
    try {
        const url = `${API_ENDPOINT}/add-origins`
        let response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'x-admin-access-token': token
            },
            body: JSON.stringify({
                email,
                origins
            })
        });
        response = await response?.json();
        return response;
    } catch (error) {
        return {
            error: true,
            message: error?.message
        }
    }
};