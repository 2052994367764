import React from "react";

export default function NavBar({ icon, pages, onPageClick, primaryButton }) {
    return <div className="navbar bg-transparent flex justify-around">
        <div>
            <a className="btn btn-ghost">{icon}</a>
        </div>
        <div className="hidden lg:flex">
            <ul className="menu menu-horizontal px-1">
                {
                    pages?.map(page => {
                        return <li><a onClick={() => { onPageClick(page?.id); }}>{page?.label}</a></li>;
                    })
                }
            </ul>
        </div>
        <div>
            <a className="btn" onClick={() => {
                primaryButton?.onClick();
            }}>{primaryButton?.label}</a>
        </div>
    </div>;
}