import React from "react";

export default function Comment({ commentData, onSelect, updateComment, isAdmin }) {
    const { title, description, no_of_upvotes, no_of_replies, userUpvoted, comment_id } = commentData || {};
    return <div className="space-y-6 w-full">
        <a onClick={() => { onSelect(comment_id); }} className="custom-card flex bg-base-100 rounded-box p-6 duration-200 hover:shadow-lg cursor-pointer justify-between items-center gap-4">
            <div>
                <div class="font-semibold mb-0.5 flex gap-2 items-start">{title}</div>
                <div class="text-base-secondary leading-relaxed mb-2">{description}</div>
                {/* <div class="flex gap-1.5 items-center text-base-secondary">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"></path>
                    </svg>
                    {no_of_replies}</div> */}
            </div>
            {!isAdmin && <button class={`px-4 py-2 rounded-box group text-center text-lg duration-150
    border border-base-content/10 bg-base-100 text-base-content`} title="Upvote post" onClick={async () => {
                    await updateComment(comment_id, "upvote");
                }} style={{ backgroundColor: userUpvoted ? "#E1643F" : "", color: userUpvoted ? "white" : "" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5  ease-in-out duration-150 group-hover:-translate-y-0.5">
                    <path d="m18 15-6-6-6 6"></path>
                </svg>
                {no_of_upvotes}</button>}
        </a>
    </div>;
}