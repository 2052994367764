import { useState } from "react";
import logoIcon from '../../assets/logo-3.png';
import NavBar from "../../components/NavBar";
import Hero from "../../components/Hero";
import Compare from "../../components/Compare";
import { useNavigate } from "react-router-dom";

export default function LandingPage() {
    const [selectedPage, setSelectedPage] = useState("landing-page");
    const navigate = useNavigate();
    const pages = [{
        id: "pricing",
        label: "Pricing"
    }]
    const onPageClick = (selectedPageId) => {
        setSelectedPage(selectedPageId);
    };
    return <div>
        <NavBar primaryButton={{
            label: "Login",
            onClick: async () => {
                // alert("Login Clicked");
                // await showToast();
                navigate("login");
            }
        }} pages={pages} icon={<div className="flex gap-2 justify-center items-center">
            <img src={logoIcon} style={{ width: '25px' }} />
            <h4 className="font-bold">Advice Fast</h4>
        </div>} onPageClick={onPageClick} />

        {/* Background Graphics */}
        <div className='gradient'></div>
        <div className='gradient2'></div>

        <Hero />
        <Compare />
    </div>;
}