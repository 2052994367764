import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import './output.css';
// import './restricted-output.css';
// import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CustomerPage from './pages/CustomerPage';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// Create a link tag to load Tailwind CSS from CDN
// const linkTag = document.createElement('link');
// linkTag.rel = 'stylesheet';
// linkTag.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@3.3.2/dist/tailwind.min.css';
// const linkTag2 = document.createElement('link');
// linkTag2.rel = 'stylesheet';
// linkTag2.href = 'https://cdn.jsdelivr.net/npm/daisyui@4.12.14/dist/full.min.css';
// const scriptTag = document.createElement('script');
// scriptTag.type = 'text/javascript';
// scriptTag.src = 'https://cdn.jsdelivr.net/npm/tailwindcss-cdn@3.4.10/tailwindcss.js';

// document.head.appendChild(linkTag);
// document.head.appendChild(linkTag2);
// document.head.appendChild(scriptTag);

// document.body.appendChild(adviceContainer);
const IframeWrapper = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframeDocument = iframeRef.current.contentDocument;

    if (iframeDocument.readyState === "complete") {
      injectStylesAndRender();
    } else {
      iframeRef.current.onload = injectStylesAndRender;
    }

    function injectStylesAndRender() {
      if (iframeDocument) {
        // Create link elements for Tailwind CSS, DaisyUI, and custom CSS
        const tailwindLink = iframeDocument.createElement("script");
        tailwindLink.type = "text/javascript";
        tailwindLink.src = "https://cdn.jsdelivr.net/npm/tailwindcss-cdn@3.4.10/tailwindcss.js";

        const daisyuiLink = iframeDocument.createElement("link");
        daisyuiLink.rel = "stylesheet";
        daisyuiLink.href = "https://cdn.jsdelivr.net/npm/daisyui@4.12.14/dist/full.min.css";

        const adviceFastGlobalCssLink = iframeDocument.createElement("link");
        adviceFastGlobalCssLink.rel = "stylesheet";
        adviceFastGlobalCssLink.href = "https://cdn.jsdelivr.net/gh/monolith-ai-corp/advice-fast-css@main/main.css";

        // Track loading state of each link
        let loadedCount = 0;

        // Function to render only after all styles are loaded
        function tryRenderComponent() {
          loadedCount += 1;
          if (loadedCount === 3) { // All styles loaded
            ReactDOM.createRoot(iframeDocument.body).render(<CustomerPage />);
          }
        }

        // Attach onload events to ensure styles are fully loaded
        tailwindLink.onload = tryRenderComponent;
        daisyuiLink.onload = tryRenderComponent;
        adviceFastGlobalCssLink.onload = tryRenderComponent;

        // Append Tailwind and DaisyUI styles to the iframe's head
        iframeDocument.head.appendChild(tailwindLink);
        iframeDocument.head.appendChild(daisyuiLink);
        iframeDocument.head.appendChild(adviceFastGlobalCssLink);
      }
    }
  }, []);

  return (
    <iframe
      ref={iframeRef}
      data-theme="light"
      title="Customer Page"
      style={{ width: "100vw", height: "100vh", border: "none", margin: 0, padding: 0 }}
    />
  );
};

const root = ReactDOM.createRoot(document.getElementById('advice-fast-root'));
root.render(
  <React.StrictMode>
    <App />
    {/* <IframeWrapper /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
